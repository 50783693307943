.root {
  margin-bottom: 18px;
  background-color: var(--mantine-color-control);
}

.control {
  &[data-active] {
    background-color: var(--mantine-color-panel);
  }
}

.label {
  &[data-active] {
    background-color: var(--mantine-color-panel);
  }
  &:hover {
    color: var(--mantine-color-dimmed);
  }
}
