.card {
  @mixin hover {
    background-color: lightgrey;
    border-radius: 8px;
    cursor: pointer;
  }
}

.footer {
  z-index: 1;
  position: sticky;
  background-color: var(--mantine-color-background);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 0;
  transition: all 0.2s linear;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.footerDisplay {
  border-top: 1px solid var(--mantine-color-tableBorder);
  background-color: var(--mantine-color-background);
  transition: all 0.2s linear;
  padding: 8px;
  height: 50px;
  gap: 10px;
}
