.label {
  font-size: 12px;
}

.wrapper {
  height: 45px;
}

.item {
  font-size: 12px;
}

.input {
  font-size: 14px;
  padding-left: 85px;
  height: 45px;
}

.section {
  width: auto;
}
