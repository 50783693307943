.column {
  border: 1px solid var(--mantine-color-tableColumn);
  border-left: none;
  width: 25%;

  &[data-noBorder='true'] {
    border-top: none;
    border-bottom: none;
  }

  &[data-firstChild='true'] {
    border-top: none;
  }

  &[data-bottomBorder='true'] {
    border-bottom: none;
  }

  &:nth-of-type(even) {
    background-color: var(--mantine-color-backgroundColor);
  }

  &:nth-of-type(odd) {
    background-color: var(--mantine-color-oddColumn);
  }
}

.titleColumn {
  align-items: center;
  background-color: var(--mantine-color-backgroundColor);
  border: 1px solid var(--mantine-color-tableColumn);
  border-left: none;

  &[data-noBorderBottom='true'] {
    border-bottom: none;
  }

  &[data-noBorderTop='true'] {
    border-top: none;
  }
}

.headerCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--mantine-color-tableColumn);
  border-left: none;
  border-bottom: none;
  background-color: var(--mantine-color-backgroundColor);

  &[data-column='true'] {
    justify-content: flex-start;

    &:nth-of-type(even) {
      background-color: var(--mantine-color-backgroundColor);
    }

    &:nth-of-type(odd) {
      background-color: var(--mantine-color-oddColumn);
    }

    &[data-last='true'] {
      border-right: none;
    }
  }
}
